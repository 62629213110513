<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :loading="loading">
          <template slot="header">
            <span class="title">Edit Subscription Plan</span>
          </template>
          <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="agencyForm">
            <form @submit.prevent="handleSubmit(save)">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <text-input name="Plan Name" v-model="plan.name" label="Plan Name" :validate="'required'"
                    :maxlength="40" />
                </div>
                <div class="col-md-6 col-sm-12">
                  <text-input name="Price" class="disabled-input" v-model="plan.amount_org" step="any" label="Price"
                    type="number" :readonly="true" :disabled="true" :validate="'required'" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="control-label">Trial Days</div>
                  <multiselect v-model="trialDays" :options="trialDaysOptions" :multiple="false" :close-on-select="true"
                    :clear-on-select="false" :show-labels="false" :searchable="false" :preserve-search="true"
                    :allowEmpty="false" label="label" track-by="value" placeholder="Select Days">
                  </multiselect>
                </div>
                <div class="col-md-6 col-sm-12">
                  <text-input name="interval" v-model="plan.interval_str" label="Interval" :disabled="true" />
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="control-label">Description</div>
                  <textarea v-model="planDesc" rows="6" class="description-textarea form-control"
                    placeholder="Plan Description Here..." :maxlength="500"></textarea>
                </div>
              </div>
              <fieldset>
                <legend>Features</legend>
                <table class="table table-bordered table-features">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in metadataVisible" :key="index">
                      <td>
                        <span>{{ item.label }}</span>
                      </td>
                      <td>
                        <text-input v-if="item.type === 'text'" v-model="item.value" type="number" :name="item.key"
                          validate="required" />
                        <template v-if="item.type === 'checkbox'">
                          <div class="checkbox abc-checkbox abc-checkbox-primary" style="padding-left: 10px">
                            <input type="checkbox" name="schedule" :id="item.key" v-model="item.value">
                            <label :for="item.key">
                              <span class="abc-label-text"></span>
                            </label>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
              <div class="row mt-5">
                <div class="col-md-12 d-flex justify-content-center">
                  <router-link class="btn btn-danger btn-primary mr-2" :to="{ name: 'agency.plans' }">
                    Back <i class="fa fa-arrow-left"></i>
                  </router-link>
                  <button class="btn btn-primary" :disabled="invalid || processing">
                    <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                      style="margin: auto;" />
                    <span v-else>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { agencyStripePlanMetadata } from '../../../constants'

export default {
  name: 'AgencyPlanEdit',
  components: { Multiselect },

  data() {
    return {
      loading: false,
      processing: false,
      agency: null,
      plan: {
        trial_period_days: 0,
      },
      planDesc: '',
      metadata: JSON.parse(JSON.stringify(agencyStripePlanMetadata)),
      trialDays: null,
    }
  },

  mounted() {
    this.agency = this.$store.state.auth.user.agency
    this.getPlan()
  },

  watch: {
    'planDesc': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          const descMetaItem = this.metadata.find(item => item.key == 'Description')
          if (descMetaItem) {
            descMetaItem.value = newValue
          } else {
            this.metadata.push(
              {
                key: "Description",
                value: newValue
              }
            );
          }
        }
      }
    },
    'plan.trial_period_days': function (val) {
      if (val) {
        this.trialDays = this.trialDaysOptions.find(item => item.value == val)
      }
    }
  },

  methods: {
    save() {
      this.processing = true

      const param = {
        agency_id: this.agency.id,
        plan_id: this.$route.params.id,
        data: {
          name: this.plan.name,
          trial_period_days: this.trialDays ? this.trialDays.value : 0,
          metadata: this.metadata.map(item => {
            return {
              key: item.key,
              value: item.value,
            }
          })
        }
      }

      this.$store
        .dispatch('agency/updatePlan', param)
        .then(() => {
          this.processing = false;
        })
        .catch((err) => {
          console.log(err);
          this.processing = false;
        })
    },

    getPlan() {
      this.loading = true;

      const param = {
        agency_id: this.agency.id,
        plan_id: this.$route.params.id
      }
      this.$store
        .dispatch('agency/getPlan', param)
        .then(({ data }) => {
          if (data.success) {
            this.plan = data.data;
            this.planDesc = this.plan.features.Description;
            if (this.plan && this.plan.metadata) {
              this.metadata = this.metadata.map((item) => {
                const temp = this.plan.metadata.find(item1 => item1.key === item.key)
                let defaultValue = item.value;
                if (temp)
                  defaultValue = temp.value;

                let value = defaultValue;

                if (value === 'true') {
                  value = true
                } else if (value === 'false') {
                  value = false
                }

                return {
                  ...item,
                  value,
                }
              })
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.$router.push({ name: 'admin.agencies.edit' });
          this.loading = false;
        })
    },
  },

  computed: {
    trialDaysOptions() {
      return [...Array(31).keys()].map(item => {
        return {
          value: Number(item),
          label: item
        }
      })
    },

    metadataVisible() {
      return this.metadata.filter(item => item.visible)
    }
  },
}
</script>

<style lang="scss" scoped>
.atom-spinner {
  display: inline-block;
}

.interval-type-select {
  margin-top: 20px;
}

.table-features {
  thead th {
    text-transform: capitalize;
  }

  td {
    padding: 10px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

div.control-label {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 5px;
}

select.custom-select {
  background-image: none;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  height: auto;
}

.description-textarea {
  border: 1px solid #e7e7e7;
  border-radius: 0;
  border-bottom: 0;
  outline: none;
  box-shadow: none;
}
</style>

<style lang="scss">
.table-features {
  td .form-group {
    margin-bottom: 0;
  }
}

.disabled-input {

  input[type="text"]:disabled,
  input[type="number"]:disabled {
    background-color: #efefef;
  }
}
</style>
